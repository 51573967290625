/* eslint-disable @typescript-eslint/no-explicit-any */
import api from "../../utilities/http.base";

import {
  ReportResponse,
  NajpopularnijiPapciReport,
} from "../../types/reports/report";
import { toast } from "react-toastify";

export const getLatestPapci = async (
  pageNum: number,
  pageSize: number,
): Promise<ReportResponse> => {
  try {
    return await api.get(
      `/reports/latest?pageNumber=${pageNum}&pageSize=${pageSize}`,
    );
  } catch (error) {
    console.error("Error fetching latest papci:", error);
    return {
      totalCount: 0,
      pageNumber: 0,
      totalPages: 0,
      hasPreviousPage: false,
      hasNextPage: false,
      items: [],
    }; // Return empty data to display empty page elements
  }
};

export const upVotePapak = async (papakId: string) => {
  try {
    return await api.patch(`/reports/${papakId}/upvote`);
  } catch (error) {
    console.error("Error upvoting papak:", error);
    toast.error("Ups! Već ste glasali za prijavu! Vaš glas neće biti registrovan.", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      theme: "dark",
    });
  }
};

export const downVotePapak = async (papakId: string) => {
  try {
    return await api.patch(`/reports/${papakId}/downvote`);
  } catch (error) {
    console.error("Error downvoting papak:", error);
    toast.error("Ups! Već ste glasali za prijavu! Vaš glas neće biti registrovan.", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      theme: "dark",
    });
  }
};

export const getReportsBySearch = async (
  pageNumber: number,
  pageSize: number,
  plateNumber: string,
): Promise<ReportResponse> => {
  try {
    return await api.get(
      `/reports?pageNumber=${pageNumber}&pageSize=${pageSize}&plateNumber=${plateNumber}`,
    );
  } catch (error) {
    console.error("Error fetching reports by search:", error);
    return {
      totalCount: 0,
      pageNumber: 0,
      totalPages: 0,
      hasPreviousPage: false,
      hasNextPage: false,
      items: [],
    }; // Return empty data to display empty page elements
  }
};

export const getNajpopularnijiPapci = async (): Promise<
  NajpopularnijiPapciReport[]
> => {
  try {
    return await api.get("/reports/most-popular");
  } catch (error) {
    console.error("Error fetching most popular papci:", error);
    return [];
  }
};
