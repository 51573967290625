import { FC } from "react";
import { PatrolaLogoIcon } from "../../Icons/icons";

import FooterLinks from "./FooterLinks/FooterLinks";
import styles from "./FooterMainBar.module.scss";

const FooterMainBar: FC = () => {
  // const backToTopHandler = () => {
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // };

  return (
    <div className={styles.container}>
      {/* <button className={styles.top} onClick={backToTopHandler} type="button">
        <BackToTopIcon />
      </button> */}
      <div className={styles.logo}>
        <PatrolaLogoIcon />
      </div>
      <FooterLinks />
    </div>
  );
};

export default FooterMainBar;
