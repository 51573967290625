import { FC } from "react";

import FooterMainBar from "./FooterMainBar/FooterMainBar";
import FooterBottomBar from "./FooterBottomBar/FooterBottomBar";
import TermsOfUse from "./TermsOfUse/TermsOfUse";

const Footer: FC = () => {
  return (
    <div>
      <TermsOfUse />
      <FooterMainBar />
      <FooterBottomBar />
    </div>
  );
};

export default Footer;
