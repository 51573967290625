import { Dispatch, FC, SetStateAction } from "react";

import styles from "./DotIndicator.module.scss";
import { DotIndicatorIcon } from "../../Icons/icons";

interface DotIndicatorProps {
  setPage: Dispatch<SetStateAction<number>>;
  page: number;
  currentPage: number;
  variant?: "primary" | "secondary" | "tertiary";
}

const DotIndicator: FC<DotIndicatorProps> = ({
  setPage,
  page,
  currentPage,
  variant,
}) => {
  const onClick = () => {
    setPage(page);
  };

  return (
    <div
      className={`${styles.dotIndicationIcon} ${
        currentPage === page ? styles.focused : ""
      }`}
      onClick={onClick}
    >
      <DotIndicatorIcon />
    </div>
  );
};

export default DotIndicator;
