import { FC } from "react";

import Typography from "../../Typography/Typography";
import Heading from "../../Heading/Heading";

import styles from "./TermsOfUse.module.scss";

const TermsOfUse: FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.headingContainer}>
        <Heading variant="h2" className={styles.heading}>
          Uslovi korištenja
        </Heading>
      </div>
      <ul>
        <li>
          <Typography className={styles.inverseTextColor}>
            <a href="https://www.patrola.ba/" target="_blank" rel="noreferrer">
              Patrola.ba
            </a>
            , u svojstvu pružatelja usluga, ne snosi nikakvu pravnu ili
            materijalnu odgovornost za sadržaj prijava ili drugih informacija
            koje su kreirali korisnici. Isto tako, naglašavamo da se ne preuzima
            nikakva zakonska odgovornost za tačnost, potpunost ili kvalitet
            sadržaja koji su postavljeni od strane korisnika.
          </Typography>
        </li>
        <li>
          <Typography className={styles.inverseTextColor}>
            <a href="https://www.patrola.ba/" target="_blank" rel="noreferrer">
              Patrola.ba
            </a>{" "}
            je platforma koja omogućuje korisnicima da objave svoje prijave i
            druge informacije, ali napominjemo da ne stvaramo vlastiti sadržaj
            ili prijave. Naša uloga je ograničena na pružanje ove platforme, a
            sve informacije su rezultat korisničkog stvaralaštva.
          </Typography>
        </li>
        <li>
          <Typography className={styles.inverseTextColor}>
            Napominjemo da, iako se trudimo održavati visoke standarde i
            promovisati pošteno i odgovorno ponašanje naših korisnika, ne možemo
            garantovati tačnost, potpunost ili kvalitet sadržaja prijava ili
            drugih informacija koje su kreirali korisnici.
          </Typography>
        </li>
        <li>
          <Typography className={styles.inverseTextColor}>
            Ukoliko imate bilo kakva pitanja ili sumnje u vezi sa sadržajem koji
            je kreirao korisnik, srdačno Vas pozivamo da nas kontaktirate putem
            naše službene web stranice ili putem e-mail adrese navedene u ovim
            uslovima korištenja. Naša stručna ekipa je na raspolaganju kako bi
            vam pružila daljnje informacije i asistirala u vezi s tim pitanjima.
          </Typography>
        </li>
      </ul>
      <div className={styles.readMoreContainer}>
        <Typography className={styles.inverseTextColor}>
          Pročitajte više na{" "}
          <a
            href="https://www.patrola.ba/uslovi-koristenja"
            target="_blank"
            rel="noreferrer"
          >
            https://www.patrola.ba/uslovi-koristenja
          </a>
          .
        </Typography>
      </div>
    </div>
  );
};

export default TermsOfUse;
