import React, { FC, ReactNode } from "react";

import Button from "../Button/Button";

interface CustomLinkProps {
  children: ReactNode;
  to: string;
}

const CustomLink: FC<CustomLinkProps> = ({ to, children }) => {
  const navigateHandler = () => {
    window.location.href = to;
  };

  return (
    <Button onClick={navigateHandler} variant="tertiary">
      {children}
    </Button>
  );
};

export default CustomLink;
