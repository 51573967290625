import { FC } from "react";
import styles from "./LoadingSpinner.module.scss";

const LoadingSpinner: FC = () => {
  return (
    <div className={styles.spinnerContainer}>
      <div className={styles.loadingSpinner} />
    </div>
  );
};

export default LoadingSpinner;
