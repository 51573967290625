import axios, { AxiosError } from "axios";

import { envConfig } from "./envConfig";

const api = axios.create({
  baseURL: envConfig.baseApiUrl,
});

const innitHttpConfig = () => {
  api.interceptors.response.use(
    (res) => {
      return res.data;
    },
    (error: AxiosError) => {
      return Promise.reject("rejected");
    },
  );
};

innitHttpConfig();

export default api;
export { innitHttpConfig };
