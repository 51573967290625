import React, { FC, SyntheticEvent, useState } from "react";

import { SearchIcon } from "../Icons/icons";

import analytics from "../../../utilities/analytics";

import styles from "./SearchBar.module.scss";

interface SearchBarProps {
  fetchData: (plateNumber: string) => void;
  onSearch: () => void;
}

const SearchBar: FC<SearchBarProps> = ({ fetchData, onSearch }) => {
  const [values, setValues] = useState({ plateNumber: "" });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [e.target.name]: e.target.value });

    analytics.searchQueryInput(e.target.value);
  };

  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (values.plateNumber.trim().length === 0) {
      return;
    }
    onSearch();
    fetchData(values.plateNumber);
    analytics.searchClick();
  };

  return (
    <form onSubmit={(e) => onSubmit(e)} className={styles.searchBar}>
      <div className={styles.searchIcon}>
        <SearchIcon />
      </div>
      <input
        onChange={(e) => onChange(e)}
        type="search"
        placeholder="Unesi broj tablica"
        name="plateNumber"
        value={values.plateNumber}
        required
      />
      <button type="submit">Pretraži</button>
    </form>
  );
};

export default SearchBar;
