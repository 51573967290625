import { FC } from "react";

import CustomLink from "../../../CustomLink/CustomLink";

import styles from "./FooterLinks.module.scss";

const FooterLinks: FC = () => {
  return (
    <div className={styles.container}>
      <CustomLink to="mailto:info@patrola.ba?subject=Patrola website kontakt">
        Podrška i kontakt
      </CustomLink>
      <CustomLink to="https://www.patrola.ba/uslovi-koristenja">
        Uslovi
      </CustomLink>
      <CustomLink to="https://www.patrola.ba/politika-privatnosti">
        Politika privatnosti
      </CustomLink>
    </div>
  );
};

export default FooterLinks;
